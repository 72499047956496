<template>
  <page-template>
    <main-block>
      <block-content>
        <card no-padding is-bordered stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header :title="$t('Invoices')">
                <btn-toolbar>
                  <btn-toolbar-toggle-wrap id="invoices_toolbar">
                    <li>
                      <drop-down
                          icon="ni-filter-alt"
                          size="lg"
                          direction="right"
                          :dot="(parseInt(statusFilter) > 0 ? 'primary':'')"
                          :title="$t('Invoices')+' '+$t('Filter')">
                        <template v-slot:body>
                          <row class="gx-6 gy-3">
                            <column default="12" md="6">
                              <label class="overline-title overline-title-alt" for="status_filter">{{$t('Status')}}</label>
                              <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                <option value="">{{ $t('All') }}</option>
                                <option v-for="status in invoiceStatuses" :key="status.id" :value="status.id">{{ $t(status.status_text)}}</option>
                              </select>
                            </column>
                          </row>
                        </template>
                        <template v-slot:footer>
                          <nk-button type="dark" dim v-on:click="getInvoices(true)">{{$t('Filter')}}</nk-button>
                        </template>
                      </drop-down>
                    </li><!-- li -->
                    <li>
                      <drop-down icon="ni ni-sort" title="" size="xs" direction="right">
                        <ul class="link-check">
                          <li><span>{{ $t('Order') }}</span></li>
                          <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getInvoices(true)]">{{ $t('ASC') }}</a></li>
                          <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getInvoices(true)]">{{ $t('DESC') }}</a></li>
                        </ul>
                      </drop-down>
                    </li>
                    <li v-if="userRole === 'is-advisor' && userData.company_id < 1">
                      <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                        <ul class="link-list-opt no-bdr">
                          <li>
                            <router-link :to="{name: 'Settings', params: {tab: 'invoice'}}">Settings</router-link>
                          </li>
                        </ul>
                      </drop-down>
                    </li>
                  </btn-toolbar-toggle-wrap>
                </btn-toolbar>
              </card-header>
            </div>
            <div class="card-inner p-0">
              <table class="table table-orders">
                <table-head></table-head>
                <tbody class="tb-odr-body">
                <table-row v-for="invoice in invoices" :key="invoice.id">
                  <table-td type="info">
                        <span class="tb-odr-id">
                          <router-link
                              :to="{name: (userRole === 'is-advisor' ? 'ViewInvoice' : 'comViewInvoice'), params:{ id: invoice.id}}">#{{ invoice.invoice_number}}</router-link>
                        </span>
                    <span class="tb-odr-date">{{ invoice.period_to }}</span>
                  </table-td>
                  <table-td type="amount">
                    <span class="tb-odr-total">
                      <span class="amount">&euro;{{ invoice.amount }}</span>
                    </span>
                    <span class="tb-odr-status">
                      <span class="badge" :style="{color: invoiceStatusRender(invoice.status).color}">{{ $t(invoiceStatusRender(invoice.status).status_text) }}</span>
                    </span>
                  </table-td>
                  <table-td type="action">
                    <div class="tb-odr-btns d-none d-sm-inline">
                      <nk-button v-if="parseInt(invoice.status) === 3" size="sm" dim type="dark" class="btn-white pos-inherit" is-icon-only-button v-on:click="prepareInvoiceDownload(invoice.id)">
                        <nio-icon icon="ni-download"></nio-icon>
                      </nk-button>
                      <router-link
                          :to="{name: (userRole === 'is-advisor' ? 'ViewInvoice' : 'comViewInvoice'), params:{ id: invoice.id}}"
                          class="btn btn-dim btn-sm btn-dark pos-inherit">{{$t('View')}}</router-link>
                    </div>
                    <router-link
                        :to="{name: (userRole === 'is-advisor' ? 'ViewInvoice' : 'comViewInvoice'), params:{ id: invoice.id}}"
                        class="btn btn-pd-auto d-sm-none">
                      <nio-icon icon="ni-chevron-right"></nio-icon>
                    </router-link>
                  </table-td>
                </table-row>
                </tbody>
              </table>
            </div>
            <div class="card-inner" v-if="pagination.totalRecords > 15">
              <pagination
                  v-model="pagination.page"
                  :records="pagination.totalRecords"
                  :per-page="15"
                  @paginate="paginationCallback"
                  theme="bootstrap4"
                  :options="{chunk: 5, texts:{count: '||'}}"/>
            </div>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {loadingController} from '@ionic/vue';
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios";
import {Card} from "@core/components/cards";
import {useStore} from "vuex"

import {TableHead, TableRow, TableTd } from "@/views/invoices/components";
import CardHeader from "@core/components/cards/CardHeader";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import invoiceFunctions from "./functions"

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";

import Pagination from "v-pagination-3"
import {useI18n} from "vue-i18n";
import useStoreHelper from '@/store/helper'
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    TableTd,
    Card,
    TableHead,
    TableRow,
    BlockContent,
    MainBlock,
    Pagination,
  },
  setup() {

    const{t} = useI18n()
    const{endPointByRole, userRole} = useStoreHelper()
    let searchFilter = ref('');
    let statusFilter = ref('')
    let order        = ref('asc')
    let invoices     = ref([])

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      getInvoices(true)
    }

    const getInvoices = async (reset) => {
      let loader = await loadingController.create({message: t('Please wait...')})
      await loader.present()
      axios.post(endPointByRole+'/invoices', {page: pagination.page, status: statusFilter.value, search: searchFilter.value, order: order.value})
          .then( (resp) => {
            if(reset){
              invoices.value = []
            }
            for(let x in resp.data.data){
              invoices.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
      .then(() => loader.dismiss())
    }

    onMounted(()=> getInvoices())

    const { invoiceStatusRender, prepareInvoiceDownload } = invoiceFunctions()

    const store = useStore()
    const invoiceStatuses = store.state.general_data.invoice_statuses
    const userData = computed(()=>{
      if(userRole.value === 'is-advisor'){
        return store.state.advisor_data
      }
      else if(userRole.value === 'is-company'){
        return store.state.user_data
      }
      return {}
    })

    return {
      invoices,
      getInvoices,
      prepareInvoiceDownload,
      invoiceStatuses,
      invoiceStatusRender,

      statusFilter,
      order,
      pagination,
      paginationCallback,
      userData,
      userRole,
    }
  }
});

</script>
